<template>
  <div class="d-times">
    <div class="row month-selector align-items-center">
      <div class="col d-flex align-items-center">
        <BDropdown
          class="month-drop"
          :text="moment(model).format('MMMM YYYY')"
        >
          <BDropdownItem
            v-for="(m, i) in selectableMonths"
            :key="`month-${i}`"
            @click="model = m"
          >
            <span class="d-flex month-item justify-content-between align-center gap-3">
              {{ moment(m).format('MMMM YYYY') }}
              <BBadge :style="{
              'background': buttonBG + '!important',
              'color': buttonText + '!important' }" variant="secondary" pill>{{ daysWithActiveSessionsForMonth(m) }}</BBadge>
            </span>
          </BDropdownItem>
        </BDropdown>
      </div>
      <div class="col-auto month-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#month">
        <i class="fa-sharp fa-regular fa-calendar-range"></i>
      </div>
    </div>
    <div class="dates-scroll-wrapper collapse show gap-3" id="month">
      <SessionDate
        v-for="(d, i) in selectableDates.sort((a, b) => a.getTime() - b.getTime())"
        :key="`date-${i}-${d.getTime()}`"
        :date="d"
        :data-selected="moment(model).isSame(d, 'date')"
        :selected="moment(model).isSame(d, 'date')"
        :dayBG="buttonBG"
        :dayText="buttonText"
        :ref="moment(model).isSame(d, 'date') ? 'selectedDate' : undefined"
        role="button"
        @click="model = d; setSelectedSession(null)"
      />
    </div>
    <hr/>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="m-0 text-bold">Select Time</p>
      <BTabs nav-class="nav-fill" pills nav-wrapper-class="" active-nav-item-class="bg-secondary" nav-item-class="">
        <BTab :title="SessionTimeConstraint.ALL" @click="selectedTimeConstraint = SessionTimeConstraint.ALL"></BTab>
        <BTab :title="SessionTimeConstraint.AM" @click="selectedTimeConstraint = SessionTimeConstraint.AM"></BTab>
        <BTab :title="SessionTimeConstraint.PM" @click="selectedTimeConstraint = SessionTimeConstraint.PM"></BTab>
      </BTabs>
    </div>
    <div>
      <div v-if="sessionsForDate.length" class="container px-0">
        <div class="row g-3">
          <div
            v-for="(s, i) in sessionsForDate"
            :key="`time-${i}-${sessionStartTime(s).getTime()}`"
            class="col-4 mt-0 mb-3"
          >
            <SessionTime
              :session="s"
              :selected="selectedSession?.sessionID === s.sessionID"
              @session-chosen="setSelectedSession(s)"
              :low-capacity-threshold="props.lowCapacityThreshold"
              :timeBG="buttonBG"
            />
          </div>
        </div>
      </div>
      <p
        v-else
        class="text-black-50"
      >
        Sorry, there are no sessions available for this date, or the last session is currently in progress and cannot be
        booked.
      </p>
    </div>
    <div v-if="selectedSession">
      <hr/>
      <TicketPricing :event="event" :tickets="tickets"/>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import moment from "moment/moment";
import {sessionStartTime} from "../functions/sessionFunctions";
import {SessionTimeConstraint} from "../types/sessionTimeConstraint";
import TicketPricing from "./TicketPricing.vue";
import SessionDate from "./SessionDate.vue";
import SessionTime from "./SessionTime.vue";
import EventCard from "./EventCard.vue";

const model = defineModel();
const selectedDate = ref([]);
const selectedSession = ref(null);
const selectedTimeConstraint = ref(SessionTimeConstraint.ALL);

// Automatically scroll the selected date into view on first mount
onMounted(() => {
  nextTick(() => {
    scrollToSelectedDate();
  });
});

watch(model, () => {
  nextTick(() => {
    scrollToSelectedDate();
  });
});

const scrollToSelectedDate = () => {
  const selectedElement = document.querySelector('.dates-scroll-wrapper [data-selected="true"]');
  if (selectedElement) {
    selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }
};

const emit = defineEmits(['sessionChosen']);

const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  minDate: {
    type: Date,
    required: true,
  },
  maxDate: {
    type: Date,
    required: true,
  },
  buttonBG: {
    type: String,
    required: true,
  },
  buttonText: {
    type: String,
    required: true,
  },
  lowCapacityThreshold: {
    type: Number,
    default: null,
    required: false,
  },
  tickets: {
    type: Array,
    required: true,
  },
});

const sessionsForDate = computed(() =>
  props.event.allSessions
    .filter((s) => moment(s['date']).isSame(moment(model.value), 'date'))
    .filter((s) => props.event.allowSalesDuringSession || moment(sessionStartTime(s)).isAfter(moment()))
    .filter((s) => {
      if (selectedTimeConstraint.value === SessionTimeConstraint.AM) {
        return moment(sessionStartTime(s)).isSameOrBefore(moment(model.value).set({
          hour: 12,
          minute: 0,
          second: 0
        }));
      }
      if (selectedTimeConstraint.value === SessionTimeConstraint.PM) {
        return moment(sessionStartTime(s)).isSameOrAfter(moment(model.value).set({
          hour: 12,
          minute: 0,
          second: 0
        }));
      }

      return true;
    })
);

const sessionsForMonth = computed(() => props.event.allSessions.filter(
  (s) => moment(s['date']).isSame(moment(model.value), 'month')
));

const selectableDates = computed(() =>
  Array.from(new Set(sessionsForMonth.value.map((s) => s['date']))).map((d) => new Date(d))
);

const selectableMonths = computed(() =>
  Array.from(
    new Set(props.event.allSessions.map((s) => {
      const date = new Date(s['date']).setDate(1);

      if (moment(date).isBefore(moment(props.minDate))) {
        return props.minDate;
      }

      return moment(date).isAfter(moment(props.maxDate)) ? props.maxDate : date;
    }))
  ).map((d) => new Date(d))
);

const setSelectedSession = (session) => {
  emit('sessionChosen', session);
  selectedSession.value = session;
}

const daysWithActiveSessionsForMonth = (date) => Array.from(
  new Set(
    props.event.allSessions.filter(
      (s) => moment(s['date']).isSame(moment(date), 'month')
    ).map((s) => s['date'])
  )
).length;
</script>

<style scoped lang="scss">
.dates-scroll-wrapper {
  overflow-x: scroll;
  margin-top: 15px;

  &.show {
    display: flex;
    padding-bottom: 10px;
  }

  &.collapsing {
    visibility: hidden;
  }
}

.month-button {
  &[aria-expanded="true"] {
    color: #DADADA
  }
}

.fa-calendar-range {
  font-size: 22px;
}


</style>

<style lang="scss">
.month-selector {
  text-align: left;

  .btn {
    background: none;
    padding: 0;
    color: #000;
    font-weight: bold;

    &:hover {
      background: none; color: #000 !important ;
    }

    &:before {
      width: 0;
      height: 0;
      content: "";
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 10px solid #DADADA;
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.month-drop {
  .badge.text-bg-secondary {
    background: #D9D9D9 !important;
    padding: 6px 15px; font-size: 12px;
    color: #000 !important;
  }
  .dropdown-menu {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 4px 4px 4px;
    .badge { }
    .dropdown-item { padding: 10px 8px;  }
  }
}

.digi-booking {
  .nav-pills {
    border-radius: 6px;
    background: #D9D9D9;
    padding: 3px;

    .nav-link {
      color: #000 !important;
    }

    .active.bg-secondary {
      background: #fff !important;
      border-radius: 6px;
    }
  }

}

.d-times {
  padding-bottom: 20px !important;
}

</style>
