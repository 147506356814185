<template>
  <BCard
    class="mt-3 text-start"
    :title="event.name"
    role="button"

    :style="{ borderColor: selected ? buttonBG : 'transparent', borderWidth: selected ? '2px' : '1px', borderStyle: 'solid' }"
  >
    <template #img>
      <BCardImg :src="getImageUrl(event)" class="card-img"></BCardImg>
    </template>
    <small class="position-relative event-text d-flex align-items-center">
      <small
        class="status p-2 rounded-circle me-2"
        :class="sessionAvailabilityClass(event)"
      />
      {{ sessionAvailabilityText(event) }}
    </small>
    <div v-if="showPricing">
      <hr/>
      <TicketPricing
        :tickets="tickets"
        :event="event"
      />
    </div>
  </BCard>
</template>

<script setup>
import {thumbImage} from "../functions/imageFunctions";
import TicketPricing from "./TicketPricing.vue";

const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  buttonBG: {
    type: String,
    required: true,
  },
  tickets: {
    type: Array,
    required: true,
  },
  showPricing: {
    type: Boolean,
    required: false,
    default: false,
  },
  selected: {
    type: Boolean,
    required: false,
    default: false,
  },
  lowCapacityThreshold: {
    type: Number,
    default: null,
    required: false,
  }
});

const sessionAvailabilityClass = (event) => {
  if (event.sessions.every((s) => s.spacesAvailable <= 0)) {
    return 'bg-danger';
  }

  if (event.sessions.every((s) => s.spacesAvailable < props.lowCapacityThreshold)) {
    return 'bg-warning';
  }

  return 'bg-success';
}

const sessionAvailabilityText = (event) => {
  if (event.sessions.every((s) => s.spacesAvailable === 0)) {
    return 'Sold Out';
  }

  if (event.sessions.every((s) => s.spacesAvailable < props.lowCapacityThreshold)) {
    return 'Low Availability';
  }

  return 'Tickets Available';
}

const getImageUrl = (event) => {
  // console.log('Button background color:', props.buttonBG);
  if (event['imageUrl']) {
    return thumbImage(event['imageUrl'], 420, 300, false);
  }

  return thumbImage('https://app.digitickets.co.uk/images/frontend/holding.png', 420, 300, false);
}
</script>

<style scoped>
.status {
  height: 10px;
  width: 10px;
  display: inline-block;
}


</style>

<style lang="scss">
.digi-booking {
  .card {
    border-radius: 6px;
    border: none;
    background: #FFF;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    padding: 12px 8px;
    .card-img {
      background-color: #CCC;
      border-radius: 4px;
    }
    .card-body {
      padding: 10px 10px 0 10px;

      h4.card-title {
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
      }
    }
  }
}
</style>
