<template>
  <div>
    <div v-if="calendarKeys.length" class="key-container">
      <div  id="calKey">
        <div
            v-for="(k, i) in calendarKeys"
            :key="i"
            class="key-item align-items-center me-2">
            <span
                class="status p-2 me-2"
                :style="`background-color: ${k.colour}`"
            />
          <small>{{ k.title }}</small>
        </div>
      </div>
    </div>
    <Calendar
        class="session-calendar"
        expanded
        transparent
        borderless
        :initial-page="{
          day: minDate.getDay(),
          month: minDate.getMonth(),
          year: minDate.getFullYear(),
        }"
        title-position="center"
        :min-date="minDate"
        :max-date="maxDate"
        :attributes="attributes"
        :from-date="minDate"
        @dayclick="emit('dayClick', $event)"
    />
<!--    <div-->
<!--        v-if="calendarKeys.length"-->
<!--        class="row mt-4 pb-3 text-start align-items-center"-->
<!--    >      <div-->
<!--        class="col-12 key-title"-->
<!--        type="button"-->
<!--        data-bs-toggle="collapse"-->
<!--        data-bs-target="#calKey"-->
<!--    >-->
<!--      <strong>Key</strong>-->
<!--      <i class="fa-regular fa-angle-down float-end"></i>-->
<!--    </div>-->
<!--    </div>-->

<!--    <div class="collapse row align-items-center text-start " id="calKey">-->
<!--      <div-->
<!--          v-for="(k, i) in calendarKeys"-->
<!--          :key="i"-->
<!--          class="position-relative col-6 d-flex align-items-center mb-4">-->
<!--        <span-->
<!--            class="status p-2 me-2"-->
<!--            :style="`background-color: ${k.colour}`"-->
<!--        />-->
<!--        <small>{{ k.title }}</small>-->
<!--      </div>-->
<!--    </div>-->
  </div>

</template>

<script setup>
import {Calendar} from "v-calendar";
import {computed, ref} from "vue";
import {dateRange, isSameDate} from "../functions/dateFunctions";

const props = defineProps({
  minDate: {
    type: Date,
    required: true
  },
  maxDate: {
    type: Date,
    required: true
  },
  useCustomDates: {
    type: [Boolean, Number],
    default: false,
    required: false,
  },
  customDates: {
    type: Array,
    required: true
  },
  calendarBgColour: {
    type: String,
    required: true
  },
  calendarTextColour: {
    type: String,
    required: true
  },
  sessionDates: {
    type: Array,
    required: true,
  }
});

const emit = defineEmits(['dayClick']);

////////////////////////////
// Computed
////////////////////////////
const sortedSessionDates = computed(() => props.sessionDates.sort((a, b) => a > b));

const calendarKeys = computed(() => {
  // If we aren't using calendar item dates, then there's no need to show a key as everything
  // shows up as the default calendar background colour.
  if (!props.useCustomDates) {
    return [];
  }

  // This is a HUGE hack, but it works and is the simplest way of getting a unique list of calendar
  // items without checking each property individually.
  return Array.from(
      new Set(
          props.customDates
              .map((d) => ({...d, dates: dateRange(d.start_date, d.end_date)}))
              .filter((c) => c.dates.filter((d) => sortedSessionDates.value.some((s) => isSameDate(s, d))).length)
              .map((c) => ({
                colour: c.calendar_item.hex_colour,
                title: c.calendar_item.title,
              }))
              .map(JSON.stringify)
      )
  ).map(JSON.parse)
});

////////////////////////////
// State
////////////////////////////
const attributes = ref([
  // Base calendar colours as configured by the CMS
  {
    highlight: {
      style: {backgroundColor: props.calendarBgColour},
      contentStyle: {
        color: props.calendarTextColour,
        padding: '20px',
      },
      fillMode: 'solid',
    },
    dates: sortedSessionDates,
    order: 0 // Order works like z-index, so we use 0 here as a fallback for when no custom config is present for a given date
  },
  // Calendar date colours as configured by the CMS
  ...props.useCustomDates ? props.customDates.map((d) => ({
    highlight: {
      style: {backgroundColor: d.calendar_item.hex_colour},
      contentStyle: {
        color: d.calendar_item.text_colour,
        padding: '20px',
      },
      fillMode: 'solid',
    },
    dates: dateRange(d.start_date, d.end_date)
        .filter((d) => sortedSessionDates.value.some((s) => isSameDate(s, d))),
    order: 1
  })) : [],
]);

</script>

<style scoped lang="scss">
.status {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 50%;
}

.key-title {
  i {
    transition: all ease-in-out 0.5s;
    position: relative;
    top: 4px;
  }

  &[aria-expanded="true"] {
    i {
      transform: rotate(180deg);
      transform-origin: center
    }
  }
}

</style>

<style lang="scss">
.key-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap; /* Prevents line breaks */
}
#calKey {
  display: flex; /* Aligns items in a row */
  flex-wrap: nowrap; /* Prevents wrapping */
  gap: 10px; /* Adds space between items */
  padding: 10px;
  min-width: max-content; /* Ensures the container stretches if needed */
  .key-item {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-shrink: 0; /* Prevents shrinking */
  }
}

.vc-light {
  background: #fff;
  background-color: #fff
}

.vc-day-content {
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #fff;
  padding: 0 !important;
  border-radius: unset !important;
  &.vc-disabled {
    background: #fff; text-decoration: line-through;
  }
  &:not(.vc-highlight-content-solid) {
    text-decoration: line-through;   color: var(--vc-day-content-disabled-color);
  }
}

.vc-day.in-month {
  background: #fff;
}

.vc-header {
  padding: 0;

  .vc-title, .vc-arrow {
    background: none
  }
}

.vc-weeks {
  padding: 0 !important;
}

.vc-weekday {
  padding: 20px 0 !important;
}

.vc-highlight {
}

.vc-day {
  aspect-ratio: 1;
}

.vc-highlight-bg-solid {
  border-radius: unset !important;
  width: 100% !important;
  height: 100% !important;
}

.vc-nav-item.is-active {
  background-color: #000 !important;
}

.vc-popover-content {
  background-color: #fff !important;
  //button { font-size: 30px; }
}

.collapse.show {
  display: flex;
}

</style>