export const bookingLink = (sessionId, customerFacingDomain, branchId, categoryId) => {
    const baseUrl = new URL(`https://${customerFacingDomain}/event/${sessionId}`);

    if (branchId) {
        baseUrl.searchParams.append('branches.branchID', branchId)
    }

    if (categoryId) {
        baseUrl.searchParams.append('catID', categoryId);
    }

    return baseUrl.toString();
}